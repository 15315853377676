import React from "react"
import CardLayout from "../layouts/CardLayout"
import { Link } from "gatsby"

import Image2 from "../imageComponents/Image2"

export default function Feature2({ children }) {
  return (
    <CardLayout>
      <Link to="/cider">
        <div className="text-center">
          <h2 className="text-lg text-indigo-600 font-semibold tracking-wide uppercase">
            Now available
          </h2>
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
            Current Cider
          </h2>
        </div>
        <div className="mt-5 mb-5">
          <Image2 />
        </div>
      </Link>
    </CardLayout>
  )
}
