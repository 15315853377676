import React from "react"

const HomeAbout = () => {
  return (
    <>
      <article className="my-8 prose prose-xl col-span-full mx-auto bg-white rounded-2xl  p-8 shadow-xl">
        <p className="text-2xl font-bold">
          Archibald James is a boutique producer of minimal-intervention wines
          and ciders. We handcraft small batches of delicious products from the
          finest fruit in Washington state.
        </p>
        <p className="text-2xl font-bold">
          We’re innovators, and we’re just getting started. <br /> We hope
          you’ll join us.
        </p>
      </article>
    </>
  )
}

export default HomeAbout
