import React from "react"

import JoinClub from "../components/homepageFeatureCards/JoinClub"
import Layout from "../components/layouts/Layout"
import GridLayout from "../components/layouts/GridLayout"
import SEO from "../components/Seo"
import Feature2 from "../components/homepageFeatureCards/Feature2"
import Feature3 from "../components/homepageFeatureCards/Feature3"
import TastingRoomCard from "../components/homepageFeatureCards/TastingRoomCard"
import HomeAbout from "../components/homepageFeatureCards/HomeAbout"

const IndexPage = () => {
  return (
    <Layout>
      <GridLayout>
        <SEO
          title="Handcrafted from the Finest Local Fruit"
          description="Archibald James is a boutique producer of minimal-intervention wines and ciders. We handcraft small batches of delicious products from the finest fruit in Washington state."
        />
        <HomeAbout />
        <Feature2 />
        <Feature3 />
        <TastingRoomCard />
        <JoinClub />
      </GridLayout>
    </Layout>
  )
}

export default IndexPage
