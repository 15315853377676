import React from "react"
import CardLayout from "../layouts/CardLayout"
import { Link } from "gatsby"

import Image3 from "../imageComponents/Image3"

export default function Feature3({ children }) {
  return (
    <CardLayout>
      <Link to="/wine">
        <div className="text-center">
          <h2 className="text-lg text-indigo-600 font-semibold tracking-wide uppercase">
            Now available
          </h2>
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
            Current Wine
          </h2>
        </div>
        <div className="mt-5 mb-5">
          <Image3 />
        </div>
      </Link>
    </CardLayout>
  )
}
